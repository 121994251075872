
<template>
    <div class="Starter">
        <div class="banner">
            <div class="bannerImg">
                <img class="bg1" src="@/assets/starter/banner.png" alt="">
            </div>
            <div class="center connectBoxW">
                <div class="bigTitle">
                    <h1>LAUNCHPAD</h1>
                    <h2>A launchpad for new projects.</h2>
                    <div>
                        <p class="rules" @click="messageBoxShow = true;messageType = 'rules'">Rules</p>
                        <a class="howtouse" href="https://docs.spacefi.io/products/starter" target="_block">How to use</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="center connectBoxW">
                <div style="display:flex;align-items: center;justify-content: center;">
                    <img class="nodataImg" style="width:200px;" src="../assets/logos/spacefiFFF.png" alt="">
                    <p>No data yet</p>
                </div>
                <!-- <ul>
                    <li v-for="(item,index) in starterData" :key="index">
                        <div class="borderbox">
                            <div class="card">
                                <div class="logo">
                                    <img :src="item.claimCoin.icon" alt="">
                                </div>
                                <h4 class="name">{{ item.poolName }} ({{ item.accessType == 0 ? 'Public' : 'Private' }})</h4>
                                <p class="ratio">Ratio: <span>1{{item.claimCoin.symbol}} = {{item.ratio}}{{item.offering.symbol}}</span></p>
                                <p class="raise">Raise size: <span>{{ (item.minSwapLevel*item.ratio).toLocaleString() }}{{ item.offering.symbol }}</span></p>
                                <p class="filled">Filled: <span v-if="timeShowType && item.Progress >= 0">{{ item.Progress.toFixed(2) }}%</span> 
                                    <span class="progress-line">
                                        <span class="progress-bar" :style="'width:'+item.Progress+'%;'"></span>
                                    </span>
                                </p>
                                <p class="access">Access Type: <span>{{ item.accessType == 0 ? 'Public' : 'Private' }}</span></p>
                                <div class="btn" @click="goDetail(item,index)">
                                    <p>{{ item.accessType == 0 && item.startTime &lt; now || item.accessType == 1 && item.endTime &lt; now ? 'Ended' : 'Enter Pool' }}</p>
                                </div>
                            </div>
                        </div>
                        <countdown :startime="item.distTime/1000 - now/1000" :tokentime="item.endTime/1000 - now/1000" :endtime="item.startTime/1000 - now/1000" :access="item.accessType"></countdown>
                    </li>
                    <li></li>
                </ul> -->
            </div>
        </div>
        <div class="howtoparticipate">
            <div class="center connectBoxW">
                <div class="howtoparticipatebox">
                    <h1>
                        How to participate
                        <p></p>
                    </h1>
                    <ul>
                        <li>
                            <img src="../assets/starter/beforesale.png" alt="">
                            <h3>Before Sale</h3>
                            <div></div>
                            <p>Get the assets needed in Launchpad from space swap or bridges</p>
                        </li>
                        <li>
                            <img src="../assets/starter/salesperiod.png" alt="">
                            <h3>Sales Period</h3>
                            <div></div>
                            <p>Commit your tokens when Launchpad begins</p>
                        </li>
                        <li>
                            <img src="../assets/starter/aftersales.png" alt="">
                            <h3>After Sales</h3>
                            <div></div>
                            <p>Claim bought tokens and return unspent fund</p>
                        </li>
                    </ul>
                </div>
                <v-Footer></v-Footer>
            </div>
            
        </div>
        <div class="rulesmessageBox" v-if="messageBoxShow" @click="closemessage">
            <div class="connectBox connectBoxW">

                <div v-if="messageType == 'rules'" class="message0" @click.stop>
                    <h1>
                        Rules details
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="rules">
                        <h4>Private Pool:</h4>
                        <p>For whitelist addresses, and can only invest once.</p>
                        <p>Member joining price:</p>
                        <h4 style="margin-top:40px;">Public pool:</h4>
                        <p>For all users and can be over raised. It'll allocate tokens by your share in pool and return unspent fund.</p>
                        <p>eg. Public pool raise size is 160,000tUST for 1,600,000 SPACE. All raised is 480,000tUST. You invested 3,000tUST.<br />The final SPACE you'll receive = 3,000tUST / 480,000tUST * 1,600,000SPACE = 10,000SPACE.<br />The returned tUST you can claim = 2,000UST.</p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import countdown from '../components/countdownasd.vue';
import { getTotalDeposit} from "../utils/space.js"
export default {
    name: "Starter",
    components:{
        countdown
    },
    data() {
        return {
            timeShowType:false,
            starterData:[],
            messageBoxShow:false,messageType:'rules'
        }
    },
    created() { },
    async mounted() {
        this.timeShowType = false;
        this.userAddress = localStorage.getItem('userAddress')
        this.starterData = JSON.parse(JSON.stringify(window.starterData))
        this.now = Date.parse(new Date());
        
        for(let i in this.starterData){
            let poolItem =this.starterData[i];
            //console.log(poolItem)
            let accessType = '';
            if(poolItem.accessType == 0){accessType = 'Public';}
            else if(poolItem.accessType == 1){accessType = 'Private';}
            else{accessType = 'UNKONW'}
            let decimals = Math.pow(10,poolItem.offering.decimals)
            
            await getTotalDeposit(poolItem,this.userAddress).then(res => {
                //console.log(res)
                this.$nextTick(() => {
                    // this.starterData[i].Progress = 0;
                    // this.starterData[i].accessTypeText = accessType;
                    this.starterData[i].accessTypeText = accessType;
                    this.starterData[i].TotalDeposit = this.starterData[i].accessType == 0 ? res/decimals : res/Math.pow(10,poolItem.claimCoin.decimals);
                    this.starterData[i].Progress = this.starterData[i].accessType == 0 ? this.starterData[i].TotalDeposit/this.starterData[i].ratio/this.starterData[i].minSwapLevel*100 : this.starterData[i].TotalDeposit/this.starterData[i].minSwapLevel*100 > 99 ? 100 : this.starterData[i].TotalDeposit/this.starterData[i].minSwapLevel*100 ;
                    if (poolItem.accessType == 1) {
                        let a = Number(poolItem.minSwapLevel * poolItem.ratio)
                        let b = Number(this.$BigNumber(res).div(Math.pow(10,poolItem.claimCoin.decimals)).times(poolItem.ratio).toString())
                        this.starterData[i].surplus = this.$BigNumber(a).minus(b).toString()
                    }
                });
            },error => { 
                //console.log(poolItem.offerContract,error)
                this.starterData[i].Progress = 0;
            }); 
        }
        this.timeShowType = true;
    },
    methods: {
        closemessage(){
            this.messageBoxShow = false
            this.messageType = ''
        },
        goDetail(item,index){
            this.$router.push({path:'LaunchpadDetail',query:{add:item.offerContract}})
        }
    }
}
</script>